// ImageGrid component
import React from 'react';
import { Style } from '../../models/Style';
import { useMediaQuery } from '@mui/material';
import Image from 'next/image';

function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

const ImageGrid = ({ imagesList, imagesLimit }: { imagesList: string[]; imagesLimit: number }) => {
  // list with diferent tailwindd css withs
  const stylesProperties = [
    'w-24 z-50',
    'w-32 z-50',
    'w-40 z-40',
    'w-48 z-30',
    'w-64 z-20',
    'w-80 z-10',
    'w-96 z-10',
  ];

  const floatingEffects = ['float_1', 'float_2', 'float_3', 'float_4', 'float_5', 'float_6'];

  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <div className='flex flex-wrap justify-center mb-32 transform_700_top'>
      {imagesList.slice(0, imagesLimit).map((style, index) => (
        <div
          className={`${
            stylesProperties[
              Math.floor(Math.random() * (stylesProperties.length / (isMobile ? 2 : 1)))
            ]
          } rounded-lg drop-shadow-xxl self-center ${
            floatingEffects[Math.floor(Math.random() * floatingEffects.length)]
          }`}
          key={index}
        >
          <Image
            width={512}
            height={512}
            src={imagesList[index] || '/default_style_image.webp'}
            className='w-full rounded-lg object-cover'
            alt={'preview image'}
          />
        </div>
      ))}
    </div>
  );
};

export default ImageGrid;
