import {
  collection,
  doc,
  getDoc,
  query,
  updateDoc,
  where,
  getDocs,
  setDoc,
} from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Style } from '../models/Style';
import axios from 'axios';

export default function styleService() {
  const baseUrl = process.env.NEXT_PUBLIC_FIREBASE_CLOUD_ENDPOINT;
  async function createStyle(style: Style): Promise<Style> {
    try {
      await setDoc(doc(db, 'style'), style);
      return style;
    } catch (e) {
      console.error('Error adding document: ', e);
      return null;
    }
  }

  async function updateStyle(styleId: string, Style: Partial<Style>): Promise<boolean> {
    try {
      const styleRef = doc(db, 'style', styleId);
      await updateDoc(styleRef, { ...Style });
      console.log('style updated ');
      return true;
    } catch (e) {
      console.error('Error updating document: ', e);
      return false;
    }
  }
  async function getStyle(id: string): Promise<Partial<Style>> {
    try {
      const response = await axios.get(`${baseUrl}/style/${id}`);
      return response.data as Style;
    } catch (e) {
      console.error('Error getting document: ', e);
      return null;
    }
  }

  async function getAllStyles(): Promise<Partial<Style>[]> {
    try {
      const url = `${baseUrl}/getStyles`;
      const response = await axios.get(url);
      return response.data;
    } catch (e) {
      console.error('Error getting document: ', e);
      return [];
    }
  }

  return {
    createStyle,
    updateStyle,
    getStyle,
    getAllStyles,
  };
}
