import { useState } from 'react';
import { addDoc, collection } from '@firebase/firestore';
import { db } from '../../lib/firebase';
import { customTextModalShow } from '../../redux/counterSlice';
import { useDispatch } from 'react-redux';
import React from 'react';
import Link from 'next/link';

const Hero_4 = () => {
  const [email, setEmail] = useState('');
  const [savedEmail, setSavedEmail] = useState(false);

  const dispatch = useDispatch();

  const handleChange = (event) => {
    setEmail(event.target.value);
  };
  const handleSavedEmailChange = (saved: boolean) => {
    setSavedEmail(saved);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    await postEmail(email);
    console.log('A name was submitted: ' + email);
  };

  const postEmail = async (email: string) => {
    try {
      if (!isValidEmail(email)) {
        dispatch(
          customTextModalShow([
            'Correo inválido',
            'Por favor ingresa una dirección de correo válida',
            'Ok',
          ])
        );
        return;
      }
      const docRef = await addDoc(collection(db, 'early_adopter'), {
        email: email,
      });
      console.log('Document written with ID: ', docRef.id);
      dispatch(
        customTextModalShow([
          'Gracias por tu interés ❤️',
          'Te enviaremos un código de descuento en cuanto esté lista la plataforma, serás de los primeros en disfrutar tus fotos de perfil generadas con IA. 😱',
          'Listo',
        ])
      );
      handleSavedEmailChange(true);
    } catch (e) {
      console.error('Error adding document: ', e);
      dispatch(
        customTextModalShow([
          'Ups, error al enviar correo',
          'Vuelve a intentar más tarde.',
          'Listo',
        ])
      );
    }
  };

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  return (
    <>
      {/* <!-- Hero --> */}
      <section className='hero relative py-20 md:pt-32'>
        <picture className='pointer-events-none absolute inset-x-0 top-0 -z-10 dark:hidden'>
          <img src='/images/gradient.jpg' alt='gradient' />
        </picture>
        <picture className='pointer-events-none absolute inset-x-0 top-0 -z-10 hidden dark:block'>
          <img src='/images/gradient_dark.jpg' alt='gradient dark' />
        </picture>

        <div className='container'>
          <div className='mx-auto max-w-2xl mt-10 py-3 md:mt-16 md:py-0 text-center hero_center_elements backdrop-blur'>
            <h1 className='mb-10 font-display text-5xl text-jacarta-700 text-white lg:text-6xl xl:text-7xl'>
              Fotos de Perfil Increíbles con
              <span className='animate-gradient'> Inteligencia Artificial</span>
            </h1>
            <Link href='/all-plans'>
              <button
                className={`bg-accent w-11/12 sm:w-86 shadow-accent-volume hover:bg-accent-dark rounded-full py-3 px-8 text-center font-semibold text-white transition-all md:m-4`}
              >
                <span>CREAR MIS FOTOS</span>
              </button>
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero_4;
