const newseLatterData = [
  {
    id: '1',
    icon: { parentBg: '#beaaf7', childBg: 'rgb(131 88 255) ', svg: 'icon-photography' },

    title: ['Sube fotos tuyas '],
    text: 'Para entender tus rasgos y cómo luces, sube mínimo 20 fotos donde aparezcas solamente tú en diferentes ángulos y fondos.',
  },
  {
    id: '2',
    icon: { parentBg: '#c4f2e3', childBg: 'rgb(16 185 129)', svg: 'icon-collections' },

    title: ['Selecciona tus estilos favoritos '],
    text: 'Agrega los estilos que desees a tu paquete final, mientras más estilos selecciones, más variedad de imágenes recibirás.',
  },
  {
    id: '3',
    icon: { parentBg: '#cddffb', childBg: 'rgb(66 138 248)', svg: 'icon-transfer' },
    title: ['Entrenamiento '],
    text: 'Nuestra inteligencia artificial se entrenará y generará las imágenes en los estilos seleccionados.',
  },
  {
    id: '4',
    icon: { parentBg: '#ffd0d0', childBg: 'rgb(239 68 68)', svg: 'icon-heart' },
    title: ['Descarga tus imágenes '],
    text: 'Te enviaremos un correo electrónico con un enlace para que puedas descargar tus imágenes y las compartas por todas tus redes sociales.',
  },
];

export { newseLatterData };
