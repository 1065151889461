import Meta from '../../components/Meta';
import { useEffect, useState } from 'react';
import Hero_4 from '../../components/hero/hero_4';
import NewseLatter from '../../components/blog/newseLatter';
import ImageGrid from '../../components/ExampleImagesGrid/ExampleImagesGrid';
import styleService from '../../services/styleService';
import { shuffle } from 'lodash';

import { Style } from '../../models/Style';
import StylesPreviewList from '../../components/StylesPreviewList/StylesPreviewList';
import EstudioFotoAILink from '../../components/estudiofotoaiLink/estudiofotoaiLink';

export type Category = {
  val: string;
  text?: string;
};

const Home_4 = () => {
  const [category, setCategory] = useState<Category>({ val: 'Man', text: 'Hombre' });
  const { getAllStyles } = styleService();
  const [allStyles, setAllStyles] = useState<Partial<Style>[]>([]);
  const imagesList = [
    'https://mfdp-resources.s3.us-east-2.amazonaws.com/styles-images/Anime+Ninja.webp',
    'https://mfdp-resources.s3.us-east-2.amazonaws.com/styles-images/Cyberpunk_King.webp',
    'https://mfdp-resources.s3.us-east-2.amazonaws.com/styles-images/woman/image%20%28116%29.png',
    'https://mfdp-resources.s3.us-east-2.amazonaws.com/styles-images/Elf+Painting.webp',
    'https://mfdp-resources.s3.us-east-2.amazonaws.com/styles-images/Face+Paint.webp',
    'https://mfdp-resources.s3.us-east-2.amazonaws.com/styles-images/Fire+Sorcerer.webp',
    'https://mfdp-resources.s3.us-east-2.amazonaws.com/styles-images/Golden+God.webp',
    'https://mfdp-resources.s3.us-east-2.amazonaws.com/styles-images/Greek+God.webp',
    'https://mfdp-resources.s3.us-east-2.amazonaws.com/styles-images/In+a+Suit.webp',
    'https://mfdp-resources.s3.us-east-2.amazonaws.com/styles-images/Line_Art_Watercolor.webp',
    'https://mfdp-resources.s3.us-east-2.amazonaws.com/styles-images/Monster+Trainer.webp',
    'https://mfdp-resources.s3.us-east-2.amazonaws.com/styles-images/Neo+Tokyo+Cyberpunk.webp',
    'https://mfdp-resources.s3.us-east-2.amazonaws.com/styles-images/woman/1697140281836.jpg',
    'https://mfdp-resources.s3.us-east-2.amazonaws.com/styles-images/woman/image%20%28124%29.png',
    'https://mfdp-resources.s3.us-east-2.amazonaws.com/styles-images/image+(115).png',
    'https://mfdp-resources.s3.us-east-2.amazonaws.com/styles-images/Tribal-Warrior.webp',
    'https://mfdp-resources.s3.us-east-2.amazonaws.com/styles-images/Vintage-Protrait-Photograph.webp',
    'https://mfdp-resources.s3.us-east-2.amazonaws.com/styles-images/man/Screenshot+2023-10-12+at+19.52.10.png',
    'https://mfdp-resources.s3.us-east-2.amazonaws.com/styles-images/Water-Sorcerer.webp',
    'https://mfdp-resources.s3.us-east-2.amazonaws.com/styles-images/1693870325898.png',
    'https://mfdp-resources.s3.us-east-2.amazonaws.com/styles-images/1693871190079.png',
    'https://mfdp-resources.s3.us-east-2.amazonaws.com/styles-images/1693871205164.png',
    'https://mfdp-resources.s3.us-east-2.amazonaws.com/styles-images/1693871457351.png',
    'https://mfdp-resources.s3.us-east-2.amazonaws.com/styles-images/man/image%20%28158%29.png',
    'https://mfdp-resources.s3.us-east-2.amazonaws.com/styles-images/man/image%20%28159%29.png',
    'https://mfdp-resources.s3.us-east-2.amazonaws.com/styles-images/man/image%20%28157%29.png',
    'https://mfdp-resources.s3.us-east-2.amazonaws.com/styles-images/woman/image+(126)+(1).png',
    'https://mfdp-resources.s3.us-east-2.amazonaws.com/styles-images/woman/1697142298094.jpg',
  ];
  useEffect(() => {
    fetchStyles();
  }, []);

  const fetchStyles = async () => {
    try {
      const styles = await getAllStyles();
      setAllStyles(styles);
    } catch (error) {
      console.error(error);
    }
  };

  const handleFilterClick = (filter: Category) => {
    setCategory(filter);
  };

  const displayedStyles = shuffle(
    allStyles.filter(
      (style: Style) =>
        style.class === 'Man' ||
        style.class === 'Woman' ||
        style.class === 'Dog' ||
        style.class === 'Cat'
    )
  );
  //  variabele with  random number from 1 to 5
  return (
    <>
      <Meta title='Mi Foto de Perfil | Generador de imágenes con inteligencia artificial' />
      <Meta desc='Genera imágenes increíbles de ti con inteligencia artificial para compartir en tus redes sociales o con familia y amigos.' />
      <meta name='twitter:card' content='summary_large_image' />
      <meta
        name='twitter:image'
        content='https://firebasestorage.googleapis.com/v0/b/mifotodeperfil-711e0.appspot.com/o/Group%203.jpg?alt=media&token=61b62b43-b17b-4268-abab-848e2bb75ea7'
      />
      <meta
        name='twitter:title'
        content='Mi Foto de Perfil | Generador de imágenes con inteligencia artificial'
      />
      <meta
        name='twitter:description'
        content='Genera imágenes increíbles de ti con inteligencia artificial para compartir en tus redes sociales o con familia y amigos.'
      />
      <meta property='og:type' content='website' />
      <meta property='og:url' content='www.mifotodeperfil.com' />
      <meta
        property='og:title'
        content='Mi Foto de Perfil | Generador de imágenes con inteligencia artificial'
      />
      <meta
        property='og:description'
        content='Genera imágenes increíbles de ti con inteligencia artificial para compartir en tus redes sociales o con familia y amigos.'
      />
      <meta
        property='og:image'
        content='https://firebasestorage.googleapis.com/v0/b/mifotodeperfil-711e0.appspot.com/o/Group%203.jpg?alt=media&token=61b62b43-b17b-4268-abab-848e2bb75ea7'
      />
      <Hero_4 />
      <ImageGrid imagesLimit={25} imagesList={imagesList} />
      <NewseLatter bgWhite={true} />
      <StylesPreviewList styles={displayedStyles} />
      <EstudioFotoAILink />
    </>
  );
};

export default Home_4;
