//page where there is a collectio of styles and user can select multiple of them and a total price is calculated

import React, { useEffect, useState } from 'react';
import WomanIcon from '@mui/icons-material/Woman';
import ManIcon from '@mui/icons-material/Man';
import { FaCat, FaDog } from 'react-icons/fa';
import { Style } from '../../models/Style';
import HeadLine from '../HeadLine';

export type Category = {
  val: string;
  text?: string;
};

const StylesPreviewList = ({ styles }: { styles: Partial<Style>[] }) => {
  const [selectedStyles, setSelectedStyles] = useState<Partial<Style>[]>([]);

  const [category, setCategory] = useState<Category>({ val: 'Man', text: 'Hombre' });
  const categoryList = [
    { val: 'Man', text: 'Hombre' },
    { val: 'Woman', text: 'Mujer' },
    { val: 'Dog', text: 'Perro' },
    { val: 'Cat', text: 'Gato' },
  ];

  const handleFilterClick = (filter: Category) => {
    setCategory(filter);
    setSelectedStyles([]);
  };

  const displayedStyles = [...styles.filter((style: Style) => style.class === category.val)];
  const renderSwitchIcon = (param) => {
    switch (param) {
      case 'Woman':
        return <WomanIcon />;
      case 'Man':
        return <ManIcon />;
      case 'Dog':
        return <FaDog className='scale-110 mr-2' />;
      case 'Cat':
        return <FaCat className='scale-110 mr-2' />;
      default:
        return <></>;
    }
  };

  return (
    <>
      <section className='relative flex flex-col justify-center py-16 md:py-24 items-center'>
        <HeadLine
          text='¡Explora nuestros estilos!'
          classes='font-display text-jacarta-700 mb-16 text-center text-3xl dark:text-white'
          image=''
          pera=''
        />
        <div className='mb-8 flex flex-wrap items-center justify-between'>
          <ul className='flex flex-wrap items-center justify-center '>
            {categoryList.map(({ val, text }) => {
              return (
                <li className='my-1 mr-2.5' key={val}>
                  <button
                    onClick={() => {
                      handleFilterClick({ val, text });
                    }}
                  >
                    <div
                      className={
                        category.val === val
                          ? 'dark:border-jacarta-600 group bg-accent border-jacarta-100 font-display flex h-9 items-center rounded-lg border px-4 text-sm font-semibold transition-colors border-transparent text-white dark:border-transparent capitalize'
                          : 'dark:border-jacarta-600 dark:bg-jacarta-900 dark:hover:bg-accent group hover:bg-accent border-jacarta-100 font-display text-jacarta-500 flex h-9 items-center rounded-lg border bg-white px-4 text-sm font-semibold transition-colors hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent dark:hover:text-white capitalize'
                      }
                    >
                      {renderSwitchIcon(val)}
                      <span>{text}</span>
                    </div>
                  </button>
                </li>
              );
            })}
          </ul>
          {/* dropdown */}
        </div>
        <div className='flex flex-wrap justify-center dark:border-jacarta-600 border-jacarta-100 rounded border-solid max-w-7xl mx-2'>
          {displayedStyles.map((style: Style) =>
            style ? (
              <div
                key={style.id}
                className={`rounded-2xl flex flex-center m-1 transition-shadow ${
                  style.default ? '' : ' cursor-pointer'
                }`}
              >
                <div className='columns-1 text-center relative w-32 sm:w-36 md:w-40 lg:w-52'>
                  <div className='flex items-center relative w-32 sm:w-36 md:w-40 lg:w-52 bg-white rounded-2lg'>
                    <img
                      src={style.previewUrls[0] ?? '/default_style_image.webp'}
                      alt={style.name}
                      className={`rounded-2lg h-auto w-auto hover:shadow-lg `}
                    />
                  </div>
                  <div
                    className={`dark:text-white text-xs md:text-sm truncate 
                      `}
                  >
                    {style.name}
                  </div>
                </div>
              </div>
            ) : (
              <> </>
            )
          )}
        </div>
      </section>
    </>
  );
};

export default StylesPreviewList;
