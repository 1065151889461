'use client';

import * as React from 'react';
import Image from 'next/image';
import Link from 'next/link';

export default function EstudioFotoAILink() {
  return (
    <section className='md:px-24  md:py-20 px-4 py-4  flex-row items-center gap-5 flex flex-wrap w-full justify-center rounded-[20px]'>
      <div className='relative flex flex-row w-auto h-100 justify-center items-center rounded-[20px] bg-white max-w-[1200px] flex-wrap'>
        <div className='flex-col justify-start items-start gap-10 inline-flex px-4 md:pl-8 my-8'>
          <div className='flex-col justify-start items-start gap-5 flex'>
            <Image
              src='/images/estudiofotoai_logo.svg'
              alt='MFDP'
              width={131}
              height={10}
              priority
            />
            <div className='max-w-[450px] w-auto text-black text-sm font-medium'>
              ¿Buscas crear fotos profesionales? Podrás hacer sesiones de fotos realistas
              seleccionando tu color de vestimenta y qué tan formal quieres lucir.{' '}
            </div>
            <div className='justify-start items-center gap-3 inline-flex'>
              <div className='text-black text-xs font-medium'>Currículum</div>
              <div className='w-1 h-1 opacity-50 bg-purple rounded-full' />
              <div className='text-black text-xs font-medium'>LinkedIn</div>
              <div className='w-1 h-1 opacity-50 bg-purple rounded-full' />
              <div className='text-black text-xs font-medium'>Empresas</div>
            </div>
          </div>
          <div className='h-10 py-3 rounded justify-center items-center gap-3 inline-flex'>
            <Link href='/all-plans'>
              <button
                onClick={() => (location.href = 'https://estudiofotoia.com')}
                className={`bg-accent w-full sm:w-full shadow-accent-volume hover:bg-accent-dark rounded-full py-3 px-8 text-center font-semibold text-white transition-all md:m-4`}
              >
                <span> Ir a Estudio Foto AI</span>
              </button>
            </Link>
          </div>
        </div>

        <Image
          src='/images/estudiofotoai_preview_3.png'
          width={352}
          height={304}
          alt='Estudio Foto AI'
          className='relative rounded-[12px] md:mr-8 mb-8 md:mb-0'
        />
      </div>
    </section>
  );
}
